import React, { Component } from 'react';
import AppTable, { IdButton } from '@launchpad/components/crud/AppTable';
import { currency } from '@launchpad/util/NumberHelper';

const getScope = (scope) => {
  if (scope === undefined || scope === null) return scope;

  switch (scope) {
    case 'PAYMENT_DEVICE':
      return 'Payment device';
    case 'PAYMENT_DEVICE_PRODUCT':
      return 'Payment device product';
    case 'PAYMENT_DEVICE_USER':
      return 'Payment device user';
    case 'ACCOUNT':
      return 'Account';
    case 'ACCOUNT_PRODUCT':
      return 'Account Product';
    case 'ACCOUNT_USER':
      return 'Account User';
    case 'USER':
      return 'User';
    case 'PRODUCT':
      return 'Product';
    case 'PRODUCT_PACKAGE':
      return 'Product package';
    case '':
      return '-';
    default:
      return scope;
  }
};
export default class ConfiguredLimits extends Component {
  _tableComponent;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { id } = this.props;
    const { entity } = this.props;

    return (
      <AppTable
        icon="hand-paper-o"
        title="Configured limits"
        onInit={(tableComponent) => (this._tableComponent = tableComponent)}
        params={{
          id,
        }}
        getRowClass={(item, index) => {
          if (item && item.limitInfo.overriddenById) return 'tr-warning-bg';

          return '';
        }}
        renderCell={(column, item, index) => {
          switch (column.name) {
            case 'limitType':
              return item.limitType.replaceAll('_', ' ');
            case 'availableLimit':
              return currency(
                item.availableLimit,
                entity.account?.currency ?? entity.currency
              );
            case 'limit':
              return currency(
                item.limit,
                entity.account?.currency ?? entity.currency
              );
            case 'limitInfo.name':
              return item.limitInfo.name ?? '-';
            case 'item.limitInfo.limitSource.type':
              return getScope(
                item.limitInfo.limitSource
                  ? item.limitInfo.limitSource.type ?? ''
                  : ''
              );
            case 'item.limitInfo.limitSource.id':
              return item.limitInfo.limitSource ? (
                <IdButton value={item.limitInfo.limitSource.id} />
              ) : (
                '-'
              );
            default:
          }
        }}
        model={this.props.model}
      />
    );
  }
}
