import React from 'react';
import { AppRoute } from '@launchpad';
import BaseModule from '@launchpad/logic/module/BaseModule';
import UsersNavigation from './UsersNavigation';
import Users from './Users';
import BulkLoadDashboard from './bulk-load/BulkLoadDashboard';
import UserForm from './user-form/UserForm';
import Organizations from './Organizations';
import OrganisationDetails from './organisations/OrganisationDetails';
import OrganisationForm from './organisations/OrganisationForm';
import AddOrganisationMember from './organisations/AddOrganisationMember';
import PriorityPassNavigation from '../priority-pass/PriorityPassNavigation';

export const NAME = 'users';

export default class UsersModule extends BaseModule {
  getName() {
    return NAME;
  }

  getPermission() {
    return 'users.*';
  }

  getPolicy() {
    return 'Admin:User:View:*';
  }

  getNavigation() {
    return [
      {
        label: 'Users',
        icon: 'users',
        path: null,
        permission: 'users.*',
        policy: 'Admin:User:View:*',
        children: [
          {
            label: 'Users',
            icon: 'users',
            path: '/',
            permission: 'users.users.*',
            policy: 'Admin:User:View:*',
          },
          {
            label: 'Organisations',
            icon: 'building',
            path: '/organisations',
            permission: 'users.organizations.*',
            policy: 'Admin:Organisation:View:*',
          },
          {
            label: 'Action logs',
            path: '/users/action-logs',
            icon: 'file',
            permission: 'users.action-logs.*',
            policy: 'Admin:User:ActionLog:View:*',
          },
          {
            label: 'Bulk Load',
            icon: 'plus',
            path: '/users/bulk-load',
            permission: 'users.bulk-load.*',
            policy: 'Admin:User:BulkLoad:Manage:*',
          },
        ],
      },
    ];
  }

  getRoutes(currentUrl = '') {
    return [
      <AppRoute
        permission="users.*"
        policy="Admin:User:View:*"
        exact
        path="/"
        component={Users}
      />,
      <AppRoute
        permission="users.organizations.*"
        policy="Admin:Organisation:View:*"
        path="/organisations/add-new"
        component={OrganisationForm}
      />,
      <AppRoute
        permission="users.organizations.*"
        policy="Admin:Organisation:Manage:*"
        path="/organisation-member/:id/add-new"
        component={AddOrganisationMember}
      />,
      <AppRoute
        permission="users.organizations.*"
        policy="Admin:Organisation:View:*"
        path="/organisations/:id"
        component={OrganisationDetails}
      />,
      <AppRoute
        permission="users.organizations.*"
        policy="Admin:Organisation:View:*"
        exact
        path="/organisations"
        component={Organizations}
      />,
      <AppRoute
        permission="users.users.*"
        policy="Admin:User:View:*"
        path="/user-details"
        component={UsersNavigation}
      />,
      <AppRoute
        permission="users.action-logs.*"
        policy="Admin:User:ActionLog:View:*"
        path={`${currentUrl}users/action-logs`}
        load={() => import('./actionLogs/ActionLogs')}
      />,
      <AppRoute
        permission="users.bulk-load.*"
        policy="Admin:User:BulkLoad:Manage:*"
        path="/users/bulk-load"
        component={BulkLoadDashboard}
      />,
      <AppRoute
        permission="custom.priority-pass.*"
        path="/priority-pass"
        component={PriorityPassNavigation}
      />,
      <AppRoute
        permission="users.users.*"
        policy="Admin:User:Manage:*"
        path="/users/all-users/add-new"
        component={UserForm}
      />,
    ];
  }
}
