import Api from '@launchpad/logic/api/Api';
import BaseModel from '@launchpad/logic/model/BaseModel';

export default class UserModel extends BaseModel {

  getMethod(){
    return "GET"
  }

  /**
   * Get list url
   */
  getListUrl() {
    return 'admin/users';
  }

  /**
   * Get details url
   */
  getDetailsUrl() {
    return 'users/details';
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'users/save';
  }

  /**
   * Get delete url
   */
  getDeleteUrl(id) {
    return `users/delete/${id}`;
  }

  async delete(id) {
    const result = await Api.call(this.getDeleteUrl(id));
    return result;
  }

  /**
   * Set user status to enabled or disabled
   * @param {int} userid
   * @param {boolean} isEnabled
   */
  async changeUserStatus(userId, isEnabled) {
    let url = 'user/disable';
    if (isEnabled) {
      url = 'user/enable';
    }
    const result = await Api.call(url, { id: userId });
    return result;
  }

  /**
   * Get changes list url
   */
  getRequestsUrl() {
    return 'change-request/list';
  }

  async geRequestChanges(id) {
    const result = await Api.call(this.getRequestsUrl(), {
      id,
    });
    return result.data;
  }

  /**
   * Get default table columns
   */
  getTableColumns() {
    return [
      {
        name: 'id',
        label: 'Id',
        sortable: true,
        type: 'field',
      },
      {
        name: 'firstName',
        label: 'First Name',
        sortable: false,
        type: 'field',
      },
      {
        name: 'lastName',
        label: 'Last Name',
        sortable: false,
        type: 'field',
      },
      {
        name: 'email',
        label: 'E-mail',
        sortable: false,
        type: 'field',
      },
      {
        name: 'kycStatus',
        label: 'KYC Status',
        sortable: false,
        type: 'field',
      },
      {
        name: 'userStatus',
        label: 'Disable / Enable User',
        sortable: false,
        type: 'field',
      },
      {
        name: null,
        label: 'Actions',
        sortable: false,
        type: 'actions',
      },
    ];
  }

  /**
   * Get wallet for the user
   *
   * @param {Integer} id Id of the user
   */
  async getAccount(id) {
    const result = await Api.call('users/wallet', {
      id,
    });

    return result.data;
  }

  /**
   * Get cards of the user
   *
   * @param {Integer} id Id of the user
   */
  async getCards(id) {
    const result = await Api.call('users/cards', {
      id,
    });

    return result.data;
  }

  /**
   * Get payment cards for specific user id
   *
   * @param {Integer} id Id of the user
   */
  async getPaymentCards(id) {
    const result = await Api.call('payment-cards/list', {
      userId: id,
    });
  }

  /**
   * Get reset password url
   */
  getResetPasswordUrl() {
    return 'users/password-reset';
  }

  async resetPassword(email) {
    const result = await Api.call(this.getResetPasswordUrl(), {
      email,
    });
    return result.data;
  }

  /**
   * Get KYC approved url
   */
  getKYCApproveUrl() {
    return 'users/kyc-status/approve';
  }

  async approve(id) {
    const result = await Api.call(this.getKYCApproveUrl(), {
      id,
    });
    return result.data;
  }

  /**
   * Get KYC reject url
   */
  getKYCRejectUrl() {
    return 'users/kyc-status/reject';
  }

  async reject(id) {
    const result = await Api.call(this.getKYCRejectUrl(), {
      id,
    });
    return result.data;
  }

  async triggerKYC(id) {
    const result = await Api.call('users/kyc-status/trigger', {
      id,
    });
    return result.data;
  }

  /**
   * Trigger eKYC again
   */
  async triggerEKYC(id) {
    const result = await Api.call('users/kyc-status/trigger-ekyc', {
      id,
    });
    return result.data;
  }

  async triggerPEP(id) {
    const result = await Api.call('users/kyc-status/trigger-pep', {
      id,
    });
    return result.data;
  }

  async triggerSIS(id) {
    const result = await Api.call('users/kyc-status/trigger-sis', {
      id,
    });
    return result.data;
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      firstName: {
        presence: {
          allowEmpty: false,
          message: '^Please enter first name',
        },
      },
      lastName: {
        presence: {
          allowEmpty: false,
          message: '^Please enter last name',
        },
      },
      email: {
        presence: {
          allowEmpty: false,
          message: '^Please enter an email address',
        },
        email: {
          message: '^Please enter a valid email address',
        },
      },
      mobileCountryCode: {
        presence: {
          allowEmpty: false,
          message: '^Please select country code',
        },
      },
      mobileShort: {
        presence: {
          allowEmpty: false,
          message: '^Please enter mobile number',
        },
      },
      birthDate: {
        presence: {
          allowEmpty: false,
          message: '^Please enter birth date',
        },
      },
      addressOne: {
        presence: {
          allowEmpty: false,
          message: '^Please enter address one',
        },
      },
      // addressTwo: {
      //   presence: {
      //     allowEmpty: false,
      //     message: '^Please enter address two'
      //   }
      // },
      city: {
        presence: {
          allowEmpty: false,
          message: '^Please enter city',
        },
      },
      postCode: {
        presence: {
          allowEmpty: false,
          message: '^Please enter post code',
        },
      },
      countryId: {
        presence: {
          allowEmpty: false,
          message: '^Please select country',
        },
      },
    };
  }
}
