import { AppConfig } from '@launchpad/types';

const config: AppConfig = {
  env: process.env.REACT_APP_ENVIRONMENT || 'dev',
  url: process.env.REACT_APP_URL || 'http://localhost',
  authStrategy: 'refresh-token',
  doNotMergeModules: true,
  notesCharLength: 500,
  policiesEnabled: true,
  modules: {
    fee: {
      partialRefundEnabled: true,
    },
  },
};

export default config;
