import { AppCheckboxInput } from '@launchpad/components';
import AppSelect from '@launchpad/components/crud/AppSelect';
import AppNumberField from '@launchpad/components/elements/form/AppNumberField';
import { FormHandler, FormSubmitWrapper, InputField } from '@launchpad/index';
import Api from '@launchpad/logic/api/Api';
import CountryModel from '@launchpad/logic/model/CountryModel';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import EditUserModel from '../models/EditUsersModel';

export default class EditUserForm extends Component {
  constructor(props) {
    super(props);

    // Initial state
    this.formHandler = new FormHandler(
      this,
      new EditUserModel({
        id: props.id,
        title: '',
        firstName: '',
        middleName: '',
        lastName: '',
        companyName: '',
        email: '',
        mobileCountryCode: '',
        mobileShort: '',
        birthDate: '',
        addressOne: '',
        addressTwo: '',
        addressThree: '',
        city: '',
        postCode: '',
        countryCode: '',
        status: '',
        customerReference: '',
        additionalData: {
          companyName: '',
          landlineCountryCode: '',
          landlineNumberShort: '',
          correspondenceAddressCountry: '',
          correspondenceAddressLineOne: '',
          correspondenceAddressLineTwo: '',
          correspondenceAddressLineThree: '',
          correspondenceAddressCity: '',
          correspondenceAddressPostcode: '',

          correspondenceOneTimeAddressCountry: '',
          correspondenceOneTimeAddressCity: '',
          correspondenceOneTimeAddressLine: '',
          correspondenceOneTimeAddressLineTwo: '',
          correspondenceOneTimeAddressLineThree: '',
          correspondenceOneTimeAddressPostcode: '',
          isCorrespondenceOneTimeAddress: false,
        },
      })
    );
  }

  componentDidMount() {
    this._loadUserData(this.props.id);
  }

  getSubmitData() {
    return {
      ...this.state.record,
      additionalData: {
        ...this.state.record.additionalData,
        landlineNumber:
          this.state.record.additionalData.landlineCountryCode &&
          this.state.record.additionalData.landlineNumberShort
            ? `${this.state.record.additionalData.landlineCountryCode}|${this.state.record.additionalData.landlineNumberShort}`.trim()
            : '',
        landlineNumberShort:
          `${this.state.record.additionalData.landlineNumberShort}`.trim(),
        correspondenceAddressCountry:
          `${this.state.record.additionalData.correspondenceAddressCountry}`.trim(),
        correspondenceAddressLineOne:
          `${this.state.record.additionalData.correspondenceAddressLineOne}`.trim(),
        correspondenceAddressLineTwo:
          `${this.state.record.additionalData.correspondenceAddressLineTwo}`.trim(),
        correspondenceAddressLineThree:
          `${this.state.record.additionalData.correspondenceAddressLineThree}`.trim(),
        correspondenceAddressCity:
          `${this.state.record.additionalData.correspondenceAddressCity}`.trim(),
        correspondenceAddressPostcode:
          `${this.state.record.additionalData.correspondenceAddressPostcode}`.trim(),

        correspondenceOneTimeAddressCountry:
          `${this.state.record.additionalData.correspondenceOneTimeAddressCountry}`.trim(),
        correspondenceOneTimeAddressCity:
          `${this.state.record.additionalData.correspondenceOneTimeAddressCity}`.trim(),
        correspondenceOneTimeAddressLine:
          `${this.state.record.additionalData.correspondenceOneTimeAddressLine}`.trim(),
        correspondenceOneTimeAddressLineTwo:
          `${this.state.record.additionalData.correspondenceOneTimeAddressLineTwo}`.trim(),
        correspondenceOneTimeAddressLineThree:
          `${this.state.record.additionalData.correspondenceOneTimeAddressLineThree}`.trim(),
        correspondenceOneTimeAddressPostcode:
          `${this.state.record.additionalData.correspondenceOneTimeAddressPostcode}`.trim(),
        isCorrespondenceOneTimeAddress:
          this.state.record.additionalData.isCorrespondenceOneTimeAddress,
      },
      phoneNumber:
        `${this.state.record.mobileCountryCode}|${this.state.record.mobileShort}`.trim(),
    };
  }

  getSubmitMethod() {
    return 'PATCH';
  }

  async resetOneTimeAddress(val) {
    if (!val) {
      this.setState({
        record: {
          ...this.state.record,
          additionalData: {
            ...this.state.record.additionalData,
            correspondenceOneTimeAddressCountry: '',
            correspondenceOneTimeAddressCity: '',
            correspondenceOneTimeAddressLine: '',
            correspondenceOneTimeAddressLineTwo: '',
            correspondenceOneTimeAddressLineThree: '',
            correspondenceOneTimeAddressPostcode: '',
          },
        },
      });
    }
  }

  async _loadUserData(id) {
    try {
      Api.call(`admin/users/${id}`, 'GET').then((result) => {
        const {
          data: {
            title,
            firstName,
            middleName,
            lastName,
            addressOne,
            addressTwo,
            addressThree,
            city,
            postCode,
            countryCode,
            status,
            email,
            mobileCountryCode,
            mobileShort,
            birthDate,
            customerReference,
            additionalData: {
              title: additionalDataTitle,
              companyName,
              landlineNumber,
              correspondenceAddressCountry,
              correspondenceAddressLineOne,
              correspondenceAddressLineTwo,
              correspondenceAddressLineThree,
              correspondenceAddressCity,
              correspondenceAddressPostcode,
              correspondenceOneTimeAddressCountry,
              correspondenceOneTimeAddressCity,
              correspondenceOneTimeAddressLine,
              correspondenceOneTimeAddressLineTwo,
              correspondenceOneTimeAddressLineThree,
              correspondenceOneTimeAddressPostcode,
              isCorrespondenceOneTimeAddress,
            },
          },
        } = result;

        // Split landline number
        let landlineCountryCode = '';
        let landlineNumberShort = '';
        if (landlineNumber && landlineNumber.trim().length > 0) {
          const landlineParts = landlineNumber.split('|');
          if (landlineParts.length === 2) {
            landlineCountryCode = `+${landlineParts[0].replace(/^\++/, '')}`;
            landlineNumberShort = `${landlineParts[1]}`;
          } else {
            landlineNumberShort = `${landlineParts[0]}`;
          }
        }

        this.setState({
          record: {
            id,
            title: title || additionalDataTitle || '',
            firstName,
            middleName,
            lastName,
            companyName,
            email,
            addressOne,
            addressTwo,
            addressThree,
            city,
            postCode,
            countryCode,
            status,
            mobileShort,
            birthDate,
            mobileCountryCode: `+${mobileCountryCode}`,
            customerReference,
            additionalData: {
              companyName,
              landlineCountryCode,
              landlineNumberShort,
              correspondenceAddressCountry,
              correspondenceAddressLineOne,
              correspondenceAddressLineTwo,
              correspondenceAddressLineThree,
              correspondenceAddressCity,
              correspondenceAddressPostcode,
              correspondenceOneTimeAddressCountry,
              correspondenceOneTimeAddressCity,
              correspondenceOneTimeAddressLine,
              correspondenceOneTimeAddressLineTwo,
              correspondenceOneTimeAddressLineThree,
              correspondenceOneTimeAddressPostcode,
              isCorrespondenceOneTimeAddress:
                isCorrespondenceOneTimeAddress &&
                (isCorrespondenceOneTimeAddress.toLowerCase() === 'true' ||
                  isCorrespondenceOneTimeAddress.toLowerCase() === 'false')
                  ? JSON.parse(isCorrespondenceOneTimeAddress)
                  : isCorrespondenceOneTimeAddress,
            },
          },
        });
      });
    } catch (e) {
      console.log('Unexpected error:', e);
    }
  }

  _getUserStatusOptions() {
    return [
      {
        id: 'active',
        name: 'Active',
      },
      {
        id: 'disabled',
        name: 'Disabled',
      },
    ];
  }

  render() {
    const { formHandler } = this;

    return (
      <div>
        <h1>Edit user</h1>
        <div
          className="element-with-blur"
          style={{ paddingTop: '2px', marginRight: 20 }}
        >
          <div className="dark-blur" />
          <div className="box-header">
            <h2>Edit user</h2>
          </div>
          <div className="add-new-group" style={{ position: 'relative' }}>
            <div className="container">
              <FormSubmitWrapper
                formHandler={formHandler}
                to={`/user-details/${this.props.id}`}
              >
                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      customInput={TextField}
                      label="Title"
                      type="text"
                      name="title"
                      value={this.state.record.title}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      customInput={TextField}
                      label="First Name"
                      type="text"
                      name="firstName"
                      value={this.state.record.firstName}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Last Name"
                      type="text"
                      name="lastName"
                      value={this.state.record.lastName}
                      handler={formHandler}
                    />
                    <DatePicker
                      selected={
                        this.state.record.birthDate
                          ? moment(this.state.record.birthDate).toDate()
                          : ''
                      }
                      onChange={(date) =>
                        this.formHandler.handleInputChange(
                          'birthDate',
                          moment(date).format('YYYY-MM-DD')
                        )
                      }
                      // maxDate={moment().subtract(18, 'years')._d}
                      maxDate={new Date()}
                      style={{ width: '100%', zIndex: 9999999999999 }}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={
                        <InputField
                          materialProps={{
                            fullWidth: true,
                          }}
                          InputLabelProps={{
                            name: 'birthDate',
                            shrink: true,
                          }}
                          label="Date of birth"
                          type="text"
                          value={this.state.record.birthDate}
                          handler={formHandler}
                        />
                      }
                    />
                    <div style={{ paddingTop: 14, zIndex: 99 }}>
                      <AppSelect
                        getOptionValue={(country) => country.iso}
                        wrapperStyle={{ marginBottom: 5 }}
                        textFieldProps={{
                          label: 'Country',
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        model={CountryModel}
                        value={this.state.record.countryCode}
                        handler={formHandler}
                        placeholder="Search a Country"
                        name="countryCode"
                      />
                    </div>
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Address Line"
                      type="text"
                      name="addressOne"
                      value={this.state.record.addressOne}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Address Line 3"
                      type="text"
                      name="addressThree"
                      value={this.state.record.addressThree}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Customer reference"
                      type="text"
                      name="customerReference"
                      value={this.state.record.customerReference}
                      handler={formHandler}
                    />
                    <div className="row new-user-phone">
                      <div className="col-sm-7 input-wrap">
                        <AppSelect
                          textFieldProps={{
                            label: 'Country Code',
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                          model={CountryModel}
                          getOptionValue={(country) => `+${country.phoneCode}`}
                          getOptionLabel={(country) =>
                            `${country.name} (+${country.phoneCode})`
                          }
                          name="additionalData.landlineCountryCode"
                          value={
                            this.state.record.additionalData.landlineCountryCode
                          }
                          handler={formHandler}
                          placeholder="Search a Country"
                        />
                      </div>
                      <div className="col-sm-5 input-wrap-mobile">
                        <AppNumberField
                          format="################"
                          label="Landline number"
                          type="tel"
                          name="additionalData.landlineNumberShort"
                          value={this.state.record.additionalData.landlineNumberShort.trim()}
                          handler={formHandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Email"
                      type="email"
                      name="email"
                      value={this.state.record.email}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Middle Name"
                      type="text"
                      name="middleName"
                      value={this.state.record.middleName}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Company name"
                      type="text"
                      name="additionalData.companyName"
                      value={this.state.record.additionalData.companyName}
                      handler={formHandler}
                    />
                    <div style={{ paddingTop: 14, zIndex: 99 }}>
                      <AppSelect
                        wrapperStyle={{ marginBottom: 5 }}
                        textFieldProps={{
                          label: 'Status',
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        options={this._getUserStatusOptions()}
                        name="status"
                        value={this.state.record.status}
                        handler={formHandler}
                        placeholder="Status"
                      />
                    </div>
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Postcode"
                      type="text"
                      name="postCode"
                      value={this.state.record.postCode}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Address Line 2"
                      type="text"
                      name="addressTwo"
                      value={this.state.record.addressTwo}
                      handler={formHandler}
                    />
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="City"
                      type="text"
                      name="city"
                      value={this.state.record.city}
                      handler={formHandler}
                    />
                    <div className="row new-user-phone">
                      <div className="col-sm-7 input-wrap">
                        <AppSelect
                          textFieldProps={{
                            label: 'Country Code',
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                          model={CountryModel}
                          getOptionValue={(country) => `+${country.phoneCode}`}
                          getOptionLabel={(country) =>
                            `${country.name} (+${country.phoneCode})`
                          }
                          name="mobileCountryCode"
                          value={this.state.record.mobileCountryCode}
                          handler={formHandler}
                          placeholder="Search a Country"
                        />
                      </div>
                      <div className="col-sm-5 input-wrap-mobile">
                        <AppNumberField
                          format="################"
                          label="Mobile"
                          type="tel"
                          name="mobileShort"
                          value={this.state.record.mobileShort.trim()}
                          handler={formHandler}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      marginTop: 30,
                      marginBottom: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ minWidth: '20%', fontSize: 18 }}>
                      Correspondence Address
                    </span>
                    <div
                      style={{
                        width: '100%',
                        backgroundColor: 'white',
                        height: 1,
                      }}
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div style={{ paddingTop: 14, zIndex: 99 }}>
                        <AppSelect
                          wrapperStyle={{ marginBottom: 7 }}
                          getOptionValue={(country) => country.iso3}
                          textFieldProps={{
                            label: 'Country',
                            InputLabelProps: {
                              shrink: true,
                            },
                          }}
                          model={CountryModel}
                          name="additionalData.correspondenceAddressCountry"
                          value={
                            this.state.record.additionalData
                              .correspondenceAddressCountry
                          }
                          handler={formHandler}
                          placeholder="Search a Country"
                        />
                      </div>
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Address Line"
                        type="text"
                        name="additionalData.correspondenceAddressLineOne"
                        value={
                          this.state.record.additionalData
                            .correspondenceAddressLineOne
                        }
                        handler={formHandler}
                      />
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Address Line 2"
                        type="text"
                        name="additionalData.correspondenceAddressLineTwo"
                        value={
                          this.state.record.additionalData
                            .correspondenceAddressLineTwo
                        }
                        handler={formHandler}
                      />
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Address Line 3"
                        type="text"
                        name="additionalData.correspondenceAddressLineThree"
                        value={
                          this.state.record.additionalData
                            .correspondenceAddressLineThree
                        }
                        handler={formHandler}
                      />
                      <div
                        style={{
                          margin: '20px 0',
                          display: 'flex',
                          alignItems: 'flex-start',
                        }}
                      >
                        <AppCheckboxInput
                          name="additionalData.isCorrespondenceOneTimeAddress"
                          value={
                            this.state.record.additionalData
                              .isCorrespondenceOneTimeAddress
                          }
                          onChange={(val) => this.resetOneTimeAddress(val)}
                          id="isCorrespondenceOneTimeAddress"
                          htmlFor="isCorrespondenceOneTimeAddress"
                          labelText="One-time address for delivery"
                          handler={formHandler}
                        />
                      </div>
                      {this.state.record.additionalData
                        .isCorrespondenceOneTimeAddress && (
                        <div>
                          <div style={{ paddingTop: 14, zIndex: 99 }}>
                            <AppSelect
                              wrapperStyle={{ marginBottom: 7 }}
                              getOptionValue={(country) => country.iso3}
                              textFieldProps={{
                                label: 'Country (One-time entry)',
                                InputLabelProps: {
                                  shrink: true,
                                },
                              }}
                              model={CountryModel}
                              name="additionalData.correspondenceOneTimeAddressCountry"
                              value={
                                this.state.record.additionalData
                                  .correspondenceOneTimeAddressCountry
                              }
                              handler={formHandler}
                              placeholder="Search for a country (one-time entry)"
                            />
                          </div>
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="City (One-time entry)"
                            type="text"
                            name="additionalData.correspondenceOneTimeAddressCity"
                            value={
                              this.state.record.additionalData
                                .correspondenceOneTimeAddressCity
                            }
                            handler={formHandler}
                          />
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Address Line One (One-time entry)"
                            type="text"
                            name="additionalData.correspondenceOneTimeAddressLine"
                            value={
                              this.state.record.additionalData
                                .correspondenceOneTimeAddressLine
                            }
                            handler={formHandler}
                          />
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Address Line Two (One-time entry)"
                            type="text"
                            name="additionalData.correspondenceOneTimeAddressLineTwo"
                            value={
                              this.state.record.additionalData
                                .correspondenceOneTimeAddressLineTwo
                            }
                            handler={formHandler}
                          />
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Address Line Three (One-time entry)"
                            type="text"
                            name="additionalData.correspondenceOneTimeAddressLineThree"
                            value={
                              this.state.record.additionalData
                                .correspondenceOneTimeAddressLineThree
                            }
                            handler={formHandler}
                          />
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Postcode (One-time entry)"
                            type="text"
                            name="additionalData.correspondenceOneTimeAddressPostcode"
                            value={
                              this.state.record.additionalData
                                .correspondenceOneTimeAddressPostcode
                            }
                            handler={formHandler}
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="City"
                        type="text"
                        name="additionalData.correspondenceAddressCity"
                        value={
                          this.state.record.additionalData
                            .correspondenceAddressCity
                        }
                        handler={formHandler}
                      />
                      <InputField
                        materialProps={{
                          fullWidth: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Postcode"
                        type="text"
                        name="additionalData.correspondenceAddressPostcode"
                        value={
                          this.state.record.additionalData
                            .correspondenceAddressPostcode
                        }
                        handler={formHandler}
                      />
                    </div>
                  </div>
                </div>
              </FormSubmitWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditUserForm.defaultProps = {
  title: '',
  id: null,
};
