import DeleteButton from '@launchpad/components/crud/DeleteButton';
import { InputField, LoadingOverlay } from '@launchpad/index';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { LPOverridable } from '../../../../../../@launchpad/components';
import ConfirmationModalDialog from '../../../../../../@launchpad/components/modal/ConfirmationModalDialog';
import { date } from '../../../../../../@launchpad/util/DateHelper';
import ToastHelper from '../../../../../../@launchpad/util/ToastHelper';
import RenderIfAllowed from '../../../../../../@launchpad/util/access-control/access-control.component';
import UsersModel from '../../models/UsersModel';

const CustomerBasicInfo = (props) => {
  const [redirect, setRedirect] = useState(false);
  const [securityQuestion, setSecurityQuestion] = useState('');
  const [securityAnswer, setSecurityAnswer] = useState('');
  const [qaEditable, setQaEditable] = useState(false);
  const [newPassword, setNewpassword] = useState(null);
  const [lastInvitationCode, setLastInvitationCode] = useState(null);
  const { user } = props;

  useEffect(() => {
    setSecurityAnswer(props.user.additionalData?.securityAnswer);
    setSecurityQuestion(props.user.additionalData?.securityQuestion);
    pullLatestInvitation(props.user.id);
  }, [props.user]);

  function pullLatestInvitation(id) {
    if (!id) return;
    new UsersModel()
      .getLastInvitation(id)
      .then((data) => {
        if (data) {
          setLastInvitationCode(data.code);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onApproveUser(id) {
    new UsersModel()
      .approveRejectKYC(id, 'VERIFIED')
      .then(() => {
        props.onRefresh();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onRejectUser(id) {
    new UsersModel()
      .approveRejectKYC(id, 'REJECTED')
      .then(() => {
        props.onRefresh();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onQASave(id) {
    setQaEditable(false);
    new UsersModel()
      .updateSecurityQA(id, securityQuestion, securityAnswer)
      .then(() => {
        props.onRefresh();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onResendInvitation(id) {
    new UsersModel()
      .getResendInvitation(id)
      .then(() => {
        props.onRefresh();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function onResetPassword(id) {
    new UsersModel()
      .resetPassword(id)
      .then((data) => {
        setNewpassword(data.newPassword);
        ToastHelper.show('Password email sent', 'success');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (redirect) {
    return <Redirect to={`/user-details/${user.id}`} />;
  }

  const kycStatus = user.kycStatus || '';
  const newKycStatus = kycStatus.replace(/#|_/g, ' ');

  return (
    <div className="main" key={user}>
      <div className="dark-blur" />
      <div className="customers-info">
        <div className="customer-details">
          <div>
            <h2 className="customer-name">
              <span className="company">
                {user.firstName} {user.lastName}
              </span>
            </h2>
          </div>
          <div>
            <div className="bottom" style={{ alignItems: 'center' }}>
              <RenderIfAllowed action="Admin:User:Manage:*">
                <Link
                  to={`/user-details/edit-user/${user.id}`}
                  className="button-info"
                  style={{ marginRight: 20 }}
                >
                  Edit
                </Link>
              </RenderIfAllowed>
            </div>
          </div>
        </div>

        <div className="customers-main">
          <div className="row">
            <div className="col">
              {!user.firstName ? (
                <LoadingOverlay />
              ) : (
                <div className="padded">
                  <LPOverridable name="user.details.basic.before" user={user} />
                  <div className="row text-white">
                    <div style={{ marginBottom: 20 }} className="col-lg-6">
                      <h4>Basic Info</h4>
                      <hr />
                      <dl>
                        <dt>Title:</dt>
                        <dd>{user.title || '-'}</dd>
                        <dt>First name:</dt>
                        <dd>{user.firstName}</dd>
                        <dt>Middle name:</dt>
                        <dd>{user.middleName || '-'}</dd>
                        <dt>Last name:</dt>
                        <dd>{user.lastName}</dd>
                        <dt>Username:</dt>
                        <dd>{user.username ? user.username : '-'}</dd>
                        <dt>E-mail address:</dt>
                        <dd>{user.email}</dd>
                        <dt>Customer id:</dt>
                        <dd>{user.id}</dd>
                        <dt>Cust. Reference:</dt>
                        <dd>{user.customerReference || '-'}</dd>
                        <dt>Birth date:</dt>
                        <dd>{date(user.birthDate, 'DD / MMMM / Y', true)}</dd>
                        <dt>Onboarding:</dt>
                        <dd>
                          {date(user.registeredAt, 'DD / MMMM / Y HH:mm')}
                        </dd>
                      </dl>
                    </div>
                    <div
                      style={{ marginBottom: 20 }}
                      className="col-lg-6"
                      id="securityQA"
                    >
                      <div>
                        <h4>Security Question</h4>
                        <hr />
                        <InputField
                          materialProps={{
                            fullWidth: true,
                            disabled: !qaEditable,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          customInput={TextField}
                          label="Security question"
                          type="text"
                          name="securityQuestion"
                          value={securityQuestion}
                          onChange={(e) => {
                            setSecurityQuestion(e.currentTarget.value);
                          }}
                        />
                        <InputField
                          materialProps={{
                            fullWidth: true,
                            disabled: !qaEditable,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          customInput={TextField}
                          label="Security answer"
                          type="text"
                          name="securityAnswer"
                          value={securityAnswer}
                          onChange={(e) =>
                            setSecurityAnswer(e.currentTarget.value)
                          }
                        />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <RenderIfAllowed action="Admin:User:Manage:*">
                            {!qaEditable && (
                              <div
                                style={{ width: 100 }}
                                className="button-info"
                                onClick={() => setQaEditable(true)}
                              >
                                <span>Edit</span>
                              </div>
                            )}
                          </RenderIfAllowed>
                          {qaEditable && (
                            <div style={{ width: 100, marginRight: 8 }}>
                              <DeleteButton
                                btnText="Save"
                                block
                                onConfirm={() => onQASave(user.id)}
                                text="Are you sure you want to change security question and answer?"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <h4>KYC</h4>
                        <hr />
                        <dl>
                          <dt>Status:</dt>
                          <dd>{newKycStatus.toUpperCase()}</dd>
                        </dl>
                        <RenderIfAllowed action="Admin:User:Manage:*">
                          <div
                            style={{
                              maxWidth: 200,
                              margin: 'auto 0 auto auto',
                            }}
                          >
                            {user.kycStatus === 'verified' ? (
                              <DeleteButton
                                btnText="Reject"
                                block
                                onConfirm={() => onRejectUser(user.id)}
                                text={`Are you sure you wish to reject this user "${`${user.firstName} ${user.lastName}`}"?`}
                              />
                            ) : (
                              <DeleteButton
                                btnText="Approve"
                                block
                                onConfirm={() => onApproveUser(user.id)}
                                text={`Are you sure you wish to approve this user "${`${user.firstName} ${user.lastName}`}"?`}
                              />
                            )}
                          </div>
                        </RenderIfAllowed>
                      </div>
                      <div>
                        <h4>Resend Invitation</h4>
                        <hr />
                        <dl>
                          <RenderIfAllowed action="Admin:User:Manage:*">
                            <dt>Resend invitation:</dt>
                            <dd>
                              <DeleteButton
                                btnText="Resend invitation"
                                className="button-info"
                                onConfirm={() => onResendInvitation(user.id)}
                                text={`Are you sure you wish resend invitation to this user "${`${user.firstName} ${user.lastName}`}"?`}
                              />
                            </dd>
                          </RenderIfAllowed>
                          <dt>Last invitation code; </dt>
                          <dd>{lastInvitationCode ?? '-'}</dd>
                        </dl>
                      </div>
                      <br />

                      <RenderIfAllowed action="Admin:User:Manage:*">
                        <div>
                          <h4>Other actions</h4>
                          <hr />
                          <dl>
                            <dt>Send reset password email:</dt>
                            <dd>
                              <DeleteButton
                                btnText="Reset password"
                                className="button-info"
                                onConfirm={() => onResetPassword(user.id)}
                                text={`Are you sure you wish to reset password to "${`${user.firstName} ${user.lastName}`}"?`}
                              />
                            </dd>
                          </dl>
                        </div>
                      </RenderIfAllowed>
                    </div>
                  </div>
                  <div className="row text-white">
                    <div style={{ marginBottom: 20 }} className="col-lg-6">
                      <h4>Address Info</h4>
                      <hr />
                      <dl>
                        <dt>Address Line One:</dt>
                        <dd>{user.addressOne || '-'}</dd>
                        <dt>Address Line Two:</dt>
                        <dd>{user.addressTwo || '-'}</dd>
                        <dt>Address Line Three:</dt>
                        <dd>{user.addressThree || '-'}</dd>
                        <dt>City:</dt>
                        <dd>{user.city}</dd>
                        <dt>Postcode:</dt>
                        <dd>{user.postCode}</dd>
                        <dt>Country code:</dt>
                        <dd>{user.countryCode}</dd>
                        <dt>Mobile:</dt>
                        <dd>{`+${user.mobile}`.replace(/\+*/, '+')}</dd>
                        <dt>Landline:</dt>
                        <dd>{user.additionalData?.landlineNumber || '-'}</dd>
                      </dl>
                    </div>
                  </div>
                  <div className="row text-white">
                    <div style={{ marginBottom: 20 }} className="col-lg-6">
                      <h4>Correspondence Address:</h4>
                      <hr />
                      <dl>
                        <dt>Address Line One:</dt>
                        <dd>
                          {user.additionalData?.correspondenceAddressLineOne ||
                            '-'}
                        </dd>
                        <dt>Address Line Two:</dt>
                        <dd>
                          {user.additionalData?.correspondenceAddressLineTwo ||
                            '-'}
                        </dd>
                        <dt>Address Line Three:</dt>
                        <dd>
                          {user.additionalData
                            ?.correspondenceAddressLineThree || '-'}
                        </dd>
                        {user.additionalData
                          ?.correspondenceOneTimeAddressCountry &&
                          user.additionalData
                            ?.correspondenceOneTimeAddressCountry !== '' && (
                            <>
                              <dt>One-time country for delivery:</dt>
                              <dd>
                                {user.additionalData
                                  ?.correspondenceOneTimeAddressCountry || '-'}
                              </dd>
                            </>
                          )}
                        {user.additionalData
                          ?.correspondenceOneTimeAddressCity &&
                          user.additionalData
                            ?.correspondenceOneTimeAddressCity !== '' && (
                            <>
                              <dt>One-time city for delivery:</dt>
                              <dd>
                                {user.additionalData
                                  ?.correspondenceOneTimeAddressCity || '-'}
                              </dd>
                            </>
                          )}
                        {user.additionalData
                          ?.correspondenceOneTimeAddressLine &&
                          user.additionalData
                            ?.correspondenceOneTimeAddressLine !== '' && (
                            <>
                              <dt>One-time address line one for delivery:</dt>
                              <dd>
                                {user.additionalData
                                  ?.correspondenceOneTimeAddressLine || '-'}
                              </dd>
                            </>
                          )}
                        {user.additionalData
                          ?.correspondenceOneTimeAddressLineTwo &&
                          user.additionalData
                            ?.correspondenceOneTimeAddressLineTwo !== '' && (
                            <>
                              <dt>One-time address line two for delivery:</dt>
                              <dd>
                                {user.additionalData
                                  ?.correspondenceOneTimeAddressLineTwo || '-'}
                              </dd>
                            </>
                          )}
                        {user.additionalData
                          ?.correspondenceOneTimeAddressLineThree &&
                          user.additionalData
                            ?.correspondenceOneTimeAddressLineThree !== '' && (
                            <>
                              <dt>One-time address line three for delivery:</dt>
                              <dd>
                                {user.additionalData
                                  ?.correspondenceOneTimeAddressLineThree ||
                                  '-'}
                              </dd>
                            </>
                          )}
                        {user.additionalData
                          ?.correspondenceOneTimeAddressPostcode &&
                          user.additionalData
                            ?.correspondenceOneTimeAddressPostcode !== '' && (
                            <>
                              <dt>One-time postcode for delivery:</dt>
                              <dd>
                                {user.additionalData
                                  ?.correspondenceOneTimeAddressPostcode || '-'}
                              </dd>
                            </>
                          )}
                        <dt>Postcode:</dt>
                        <dd>
                          {user.additionalData?.correspondenceAddressPostcode ||
                            '-'}
                        </dd>
                        <dt>City:</dt>
                        <dd>
                          {user.additionalData?.correspondenceAddressCity ||
                            '-'}
                        </dd>
                        <dt>Country code:</dt>
                        <dd>
                          {user.additionalData?.correspondenceAddressCountry ||
                            '-'}
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModalDialog
        show={newPassword}
        text={
          <p>
            The new password is <strong>{newPassword}</strong> <br /> <br /> An
            email is sent to the user with this password. <br />
            Note that once you close this dialog you won't be able to see the
            password value again.
          </p>
        }
        onModalClose={() => setNewpassword(null)}
        onSubmit={() => setNewpassword(null)}
        cancelButton={null}
        submitButton="Close"
      />
    </div>
  );
};

export default CustomerBasicInfo;
