import BaseModel from '@launchpad/logic/model/BaseModel';

export default class AccountPaymentDeviceModel extends BaseModel {
  /**
   * Get Method
   */
  getMethod() {
    return 'GET';
  }

  /**
   * Get submit url
   */
  getSubmitUrl() {
    return 'admin/payment-devices/issue';
  }

  /**
   * Get validation rules
   */
  getValidationRules() {
    return {
      productId: {
        presence: {
          allowEmpty: false,
          message: '^Please choose product',
        },
      },
      embossCardholderName: {
        presence: {
          allowEmpty: false,
          message: '^Please enter cardholder name',
        },
      },
    };
  }

  customValidation(record) {
    const errors = [];

    const {
      showAddressForm,
      deliveryAddress: {
        addressLineOne,
        addressLineTwo,
        postcode,
        city,
        country,
      },
    } = record;

    if (!showAddressForm) return [];

    const mandatoryFields = [
      'firstName',
      'lastName',
      'addressLineOne',
      'postcode',
      'city',
      'country',
    ];

    mandatoryFields.forEach((mandatoryField) => {
      if (
        !record.deliveryAddress[mandatoryField] ||
        record.deliveryAddress[mandatoryField].trim().length === 0
      ) {
        errors.push({
          field: `deliveryAddress.${mandatoryField}`,
          message: 'Please fill the field',
        });
      }
    });

    return errors;
  }
}
