import PermissionService from '@launchpad/logic/services/PermissionService';
import $ from 'jquery';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { LPOverridable } from '../../../index';
import ModuleService from '../../../logic/module/ModuleService';
import { OverrideService } from '../../../logic/services';
import { requestPermission } from '../../../util/access-control/access-control.utils';
import SideMenuItem from './SideMenuItem';

export class SideBar extends Component {
  modules;

  moduleService;

  constructor(props) {
    super(props);

    this.moduleService = new ModuleService();
    this.modules = this.moduleService.getModules();

    this.state = {
      navigation: this.getNavigation()
    };
  }

  componentDidMount() {
    if ($(window).width() !== undefined && $(window).width() < 1440) {
      this.closeSideMenu();
      $('#collapse').css({ display: 'block', 'margin-top': '12px' });
      $('.arrow').hide();
    }
    if ($('.dashboard-side').hasClass('collapsed')) {
      $('.arrow').hide();
    } else {
      // $('.sideMenuList').find('.sub-nav').parent().find('a').append('<i class="fa fa-angle-left arrow" aria-hidden="true"></i>');
    }

    const toggleMenu = function() {
      const el = $('.sideMenuList')
        .find('> li')
        .find('> a');
      el.on('click', function() {
        const elm = $(this);
        const parentLi = elm.closest('li');

        parentLi
          .closest('ul')
          .children()
          .not(parentLi)
          .each(function(index, li) {
            const $li = $(li);
            $li.find('.sub-nav').slideUp();
            $li.find('.arrow').removeClass('arrow-down');
          });

        // console.error(parentLi);

        elm
          .parent()
          .find('.sub-nav')
          .slideToggle();
        elm
          .parent()
          .find('.arrow')
          .toggleClass('arrow-down');
      });
      // $('.sideMenuList').find('.sub-nav').parent().find('a').append('<i class="fa fa-angle-left arrow" aria-hidden="true"></i>');
    };
    toggleMenu();
  }

  getNavigation() {
    const navigationItems = this._getNavigationItems();

    const permissionedNavigationItems = this._filterNavigationItemsPerPermission(
      navigationItems
    );

    return permissionedNavigationItems;
  }

  showSideMenu() {
    $('.dashboard-side').removeClass('collapsed');
    $('.dashboard-main').removeClass('full-width');
    $('.dashboard-menu-top').removeClass('fullsize');
    $('.breadcrumb').removeClass('small');
    $('.collapse-menu').show();
    $('#collapse').hide();
    $('.sub-nav').css({ display: 'none' });
    $('.sub-nav').removeClass('toggled-menu');
    $('.arrow').show();
    $('.arrow').removeClass('arrow-down');
    $('.sideMenuList li a span').css({ display: 'inline-block' });
    // $('.sideMenuList').find('.sub-nav').parent().find('a').append('<i class="fa fa-angle-left arrow" aria-hidden="true"></i>');
  }

  closeSideMenu() {
    $('.dashboard-side').addClass('collapsed');
    $('.dashboard-main').addClass('full-width');
    $('.dashboard-menu-top').addClass('fullsize');
    $('.breadcrumb').addClass('small');
    $('.collapse-menu').hide();
    $('#collapse').show();
    $('.sub-nav').css({ display: 'none' });
    $('.sideMenuList li a span').css({ display: 'none' });
    $('#collapse').css({ position: 'relative', height: '40px' });
    $('.sub-nav').addClass('toggled-menu');
    $('.arrow').hide();
    $('.dashboard-main ').click(function() {
      $('.toggled-menu').css({ display: 'none' });
    });
    $('.toggled-menu li').click(function() {
      $('.toggled-menu').css({ display: 'none' });
    });
    // let sideMenu = function () {
    //   let el = $('.sideMenuList li');
    //   el.hover(function () {
    //     let elm = $(this);
    //     elm.find('.toggled-menu').addClass('visible');
    //   }, function () {
    //     let elm = $(this);
    //     elm.find('.toggled-menu').removeClass('visible');
    //   });
    // }
    // sideMenu();
  }

  _getFirstRoute() {
    if (this.modules.length === 0) return null;

    return this._getFirstPath(this.modules[0].getNavigation());
  }

  _getFirstPath(navigation) {
    for (let i = 0; i < navigation.length; i++) {
      const item = navigation[i];
      if (item.path) return item.path;
      if (item.children) return this._getFirstPath(item.children);
    }

    return null;
  }

  _getNavigationItems() {
    return OverrideService.getSideNavigation(this._getItems());
  }

  _filterNavigationItemsPerPermission(items) {
    // filter children per permission
    const filteredItems = items
      .filter(topItem => {
        if (
          topItem.policy &&
          this.props.policies &&
          OverrideService.getConfig().policiesEnabled === true
        ) {
          return requestPermission(this.props.policies, [topItem.policy]);
        }
        return true;
      })
      .map(item => {
        let newChildren;
        if (item.children) {
          newChildren = item.children.filter(child => {
            if (
              child.policy &&
              this.props.policies &&
              OverrideService.getConfig().policiesEnabled === true
            ) {
              return requestPermission(this.props.policies, [child.policy]);
            }
            return PermissionService.checkPermissionSet(
              child.permission,
              this.props.permissions
            );
          });
        }
        return { ...item, children: newChildren };
      });
    return filteredItems;
  }

  _getItems() {
    return this.moduleService.getNavigation();
  }

  render() {
    const items = [];

    // TODO: Remove, this is a hack for demo purposes
    const navigationItems = this.state.navigation.filter(x => {
      if (
        this.props.profile &&
        this.props.profile.username.indexOf('useradmin') === 0
      ) {
        return x.label === 'Users';
      }
      return true;
    });

    navigationItems.forEach((item, index) => {
      const key = `side-menu-item-${index}`;
      items.push(<SideMenuItem {...item} key={key} />);
    });
    return (
      <div className="dashboard-side">
        <LPOverridable name="sidemenu.logo">
          <div className="dash-logo">
            <img
              src={require('../../../assets/Launchpad_logoWhite.png')}
              alt=""
            />
          </div>
        </LPOverridable>
        <div className="side-menu">
          <i
            id="collapse"
            onClick={this.showSideMenu}
            style={{
              color: '#fff'
            }}
            className="fa fa-bars"
            aria-hidden="true"
          />

          <p className="collapse-menu" onClick={this.closeSideMenu}>
            <i
              style={{
                color: '#fff'
              }}
              className="fa fa-angle-double-left"
              aria-hidden="true"
            />
            Collapse menu
          </p>

          <ul className="sideMenuList">{items}</ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.auth.profile,
    permissions: state.auth.permissions,
    policies: state.auth.policies
  };
};

export default withRouter(connect(mapStateToProps, null)(SideBar));
