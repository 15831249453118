import AppSelect from '@launchpad/components/crud/AppSelect';
import AppSelectWithSearch from '@launchpad/components/crud/AppSelectWithSearch';
import { FormHandler, FormSubmitWrapper, InputField } from '@launchpad/index';
import Api from '@launchpad/logic/api/Api';
import { PaymentDeviceProductTypeModel } from '@launchpad/logic/model/ProductTypeModel';
import { TextField } from '@material-ui/core';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AppCheckboxInput } from '../../../../../../@launchpad/components';
import CountryModel from '../../../../../../@launchpad/logic/model/CountryModel';
import BreadcrumbHelper from '../../../../../../@launchpad/util/BreadcrumbHelper';
import AccountPaymentDeviceModel from '../../models/AccountPaymentDeviceModel';

export default class AccountAddPaymentDevice extends Component {
  constructor(props) {
    super(props);
    // Initial state
    this.formHandler = new FormHandler(
      this,
      new AccountPaymentDeviceModel({
        accountId: props.match.params.id,
        productId: '',
        userId: '',
        embossCardholderName: '',
        embossCompanyName: '',
        expirationDate: '',
        showAddressForm: false,
        deliveryAddress: {
          firstName: '',
          lastName: '',
          addressLineOne: '',
          addressLineTwo: '',
          addressLineThree: '',
          postcode: '',
          city: '',
          country: '',
        },
      }),
      { account: {} }
    );

    if (props.match.params.id) {
      BreadcrumbHelper.setCrumbs([
        {
          label: 'Account details',
          link: `/accounts/details/${props.match.params.id}`,
        },
      ]);

      BreadcrumbHelper.addCrumb(props.match.params.id);
    }
  }

  componentDidMount() {
    this._getUserDetails();
  }

  getSubmitData() {
    return {
      ...this.state.record,
      userId:
        this.state.record?.userId && this.state.record.userId.trim().length > 0
          ? this.state.record.userId
          : this.state.account.user.id,
      additionalData: {
        embossCardholderName: this.state.record.embossCardholderName,
        embossCompanyName: this.state.record.embossCompanyName,
      },
      expirationDate: this.state.record.expirationDate || null,
      deliveryAddress: this.state.record.showAddressForm
        ? {
            firstName: this.state.record.deliveryAddress.firstName || null,
            lastName: this.state.record.deliveryAddress.lastName || null,
            addressLineOne:
              this.state.record.deliveryAddress.addressLineOne || null,
            addressLineTwo:
              this.state.record.deliveryAddress.addressLineTwo || null,
            addressLineThree:
              this.state.record.deliveryAddress.addressLineThree || null,
            postcode: this.state.record.deliveryAddress.postcode || null,
            city: this.state.record.deliveryAddress.city || null,
            country: this.state.record.deliveryAddress.country || null,
          }
        : null,
    };
  }

  async _getUserDetails() {
    const { id } = this.props.match.params;
    const getUrl = `admin/accounts/${id}`;

    try {
      const result = await Api.call(getUrl, 'GET');
      this.setState({
        account: result.data,
        record: {
          ...this.state.record,
          userId: result.data?.user.id,
        },
      });
    } catch (e) {}
  }

  render() {
    const { formHandler } = this;

    return (
      <div>
        <h1>Add new card</h1>
        <div
          className="element-with-blur"
          style={{ paddingTop: '2px', marginRight: 20 }}
        >
          <div className="dark-blur" />
          <div className="box-header">
            <h2>Add new card</h2>
          </div>
          <div className="add-new-group" style={{ position: 'relative' }}>
            <div className="container">
              <FormSubmitWrapper
                formHandler={formHandler}
                to={`/accounts/details/${this.props.match.params.id}`}
              >
                <div className="row">
                  <div className="col-md-6">
                    {this.state.account && this.state.account.accountNumber && (
                      <InputField
                        materialProps={{
                          fullWidth: true,
                          disabled: true,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        label="Account number"
                        type="text"
                        name="accountNumber"
                        value={this.state.account.accountNumber}
                        handler={formHandler}
                      />
                    )}
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Emboss cardholder name"
                      type="text"
                      name="embossCardholderName"
                      value={this.state.account.embossCardholderName}
                      handler={formHandler}
                    />
                    <div style={{ paddingTop: 14, zIndex: 99 }}>
                      <AppSelect
                        getOptionValue={(product) => product.id}
                        getOptionLabel={(product) =>
                          `${product.name} (${product.currency})`
                        }
                        textFieldProps={{
                          label: 'Product',
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        model={PaymentDeviceProductTypeModel}
                        name="productId"
                        value={this.state.record.productId}
                        handler={formHandler}
                        placeholder="Search a product"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    {this.state?.account?.id ? (
                      <AppSelectWithSearch
                        url={`admin/tnbank/users/account-related-users/${this.state?.account?.id}`}
                        label="User"
                        name="userId"
                        value={this.state.record.userId}
                        handler={formHandler}
                        placeholder="Search a user"
                        wrapperStyle={{ zIndex: 99999 }}
                        getOptionValue={(user) => user.id}
                        getOptionLabel={(user) =>
                          `${user.firstName} ${user.lastName} (${user.username}) (${user.customerReference})`
                        }
                      />
                    ) : null}
                    <InputField
                      materialProps={{
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Emboss company name"
                      type="text"
                      name="embossCompanyName"
                      value={this.state.record.embossCompanyName}
                      handler={formHandler}
                    />
                    <DatePicker
                      selected={
                        this.state.record.expirationDate
                          ? moment(this.state.record.expirationDate).toDate()
                          : ''
                      }
                      onChange={(date) => {
                        formHandler.handleInputChange(
                          'expirationDate',
                          moment(date).format('YYYY-MM-DD')
                        );
                      }}
                      style={{ width: '100%' }}
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={
                        <InputField
                          materialProps={{
                            fullWidth: true,
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{}}
                          label="Card expiration date"
                          type="text"
                          value=""
                          name="expirationDate"
                          handler={formHandler}
                        />
                      }
                    />
                  </div>

                  <div
                    className="col-md-12"
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <AppCheckboxInput
                      name="showAddressForm"
                      value={this.state.record.showAddressForm}
                      handler={formHandler}
                      id="showAddressForm"
                      htmlFor="showAddressForm"
                      labelText="Send card to another address"
                    />

                    {this.state.record.showAddressForm && (
                      <div className="row" style={{ marginTop: 10 }}>
                        <div className="col-md-6" style={{ marginBottom: 20 }}>
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="First name"
                            type="text"
                            name="deliveryAddress.firstName"
                            value={this.state.record.deliveryAddress.firstName}
                            handler={formHandler}
                          />
                        </div>
                        <div className="col-md-6" style={{ marginBottom: 20 }}>
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Last name"
                            type="text"
                            name="deliveryAddress.lastName"
                            value={this.state.record.deliveryAddress.lastName}
                            handler={formHandler}
                          />
                        </div>
                        <div className="col-md-6" style={{ marginBottom: 20 }}>
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            customInput={TextField}
                            label="Address line one"
                            type="text"
                            name="deliveryAddress.addressLineOne"
                            value={
                              this.state.record.deliveryAddress.addressLineOne
                            }
                            handler={formHandler}
                          />
                        </div>
                        <div className="col-md-6" style={{ marginBottom: 20 }}>
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Address line two"
                            type="text"
                            name="deliveryAddress.addressLineTwo"
                            value={
                              this.state.record.deliveryAddress.addressLineTwo
                            }
                            handler={formHandler}
                          />
                        </div>
                        <div className="col-md-6" style={{ marginBottom: 20 }}>
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="City"
                            type="text"
                            name="deliveryAddress.city"
                            value={this.state.record.deliveryAddress.city}
                            handler={formHandler}
                          />
                        </div>
                        <div className="col-md-6" style={{ marginBottom: 20 }}>
                          <InputField
                            materialProps={{
                              fullWidth: true,
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            label="Postcode"
                            type="text"
                            name="deliveryAddress.postcode"
                            value={this.state.record.deliveryAddress.postcode}
                            handler={formHandler}
                          />
                        </div>
                        <div
                          className="col-md-6"
                          style={{
                            paddingTop: 14,
                            zIndex: 99,
                          }}
                        >
                          <AppSelect
                            getOptionValue={(country) => country.iso3}
                            wrapperStyle={{ marginBottom: 5 }}
                            textFieldProps={{
                              label: 'Country',
                              InputLabelProps: {
                                shrink: true,
                              },
                            }}
                            model={CountryModel}
                            name="deliveryAddress.country"
                            value={this.state.record.deliveryAddress.country}
                            handler={formHandler}
                            placeholder="Select a Country"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </FormSubmitWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
