/* eslint-disable react/no-unused-state */
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

const theme = createMuiTheme({
  // For Underline Color After Click
  palette: {
    primary: { main: 'rgba(255, 255, 255, 1)' }
  },
  // For Underline Hover Color
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: '1px solid #ffffff!important'
        },
        '&:after': {
          borderBottom: '2px solid #0275d8!important'
        }
      },
      root: {
        // Name of the rule
        color: 'rgba(255, 255, 255, 1)' // Some CSS,
      }
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(255, 255, 255, 1)'
      }
    }
  }
});

const styles = themes => ({
  root: {
    flexGrow: 1,
    // height: 240,
    color: 'white'
  },
  input: {
    display: 'flex',
    // padding: 0,
    color: 'white',
    // maxHeight: 60,
    minHeight: 30,
    fontWeight: 300,
    alignItems: 'center'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    color: 'white'
  },
  chip: {
    margin: `${themes.spacing.unit / 2}px ${themes.spacing.unit / 4}px`
  },
  chipFocused: {
    backgroundColor: emphasize(
      themes.palette.type === 'light'
        ? themes.palette.grey[300]
        : themes.palette.grey[700],
      0.08
    )
  },
  noOptionsMessage: {
    padding: `${themes.spacing.unit}px ${themes.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16,
    color: 'white',
    fontWeight: 300
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
    color: 'white'
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: themes.spacing.unit,
    left: 0,
    right: 0,
    cursor: 'pointer'
  }
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <TextField
        fullWidth
        InputProps={{
          disableUnderline: props.selectProps.textFieldProps.disableUnderline,
          color: 'white',
          inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            color: 'white',
            ...props.innerProps
          }
        }}
        {...props.selectProps.textFieldProps}
      />
    </MuiThemeProvider>
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 300 : 400
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

class AppSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      items: [],
      loading: false
    };
  }

  static getDerivedStateFromProps(props, state) {
    const stateChange = {};
    if (props.value) {
      const selectedOption = state.items.find(
        x => props.getOptionValue(x) === props.value
      );
      stateChange.selectedOption = selectedOption;
    } else {
      stateChange.selectedOption = null;
    }
    if (props.options) {
      stateChange.items = props.options;
    }
    return stateChange;
  }

  componentDidMount() {
    this._loadItems();
  }

  handleChange(option) {
    this.setState(
      {
        selectedOption: option
      },
      () => {
        if (this.props.handler) {
          this.props.handler.handleInputChange(
            this.props.name,
            this.props.getOptionValue(option)
          );
        }

        this.props.onChange(this.props.getOptionValue(option), option);
      }
    );
  }

  getValidationError() {
    const messages = [];

    if (!this.props.handler) return messages;
    this.props.handler
      .getErrors()
      .filter(x => x.field === this.props.name)
      .forEach(error => messages.push(error.message));
    if (messages.length === 0) {
      return null;
    }
    return (
      <div
        className="select-validation-message"
        key={`${this.props.name}-error`}
      >
        {messages.join('<br/>')}
      </div>
    );
  }

  /**
   * Load items
   */
  async _loadItems() {
    if (!this.props.model) {
      if (this.props.options) {
        let selectedOption = null;
        if (this.props.value) {
          selectedOption = this.props.options.find(
            x => this.props.getOptionValue(x.id) === this.props.value
          );
        }
        this.setState({
          items: this.props.options,
          selectedOption
        });
        return;
      }
    }

    // Show loader
    this._loadingOn();

    try {
      const results = await this._getModel().fetchFlat(this.props.params ?? {});

      let selectedOption = null;
      if (this.props.value) {
        selectedOption = results.find(
          x => this.props.getOptionValue(x.id) === this.props.value
        );
      }

      let items = results;
      if (this.props.empty) {
        items = [
          {
            id: '',
            name:
              typeof this.props.empty === 'string'
                ? this.props.empty
                : '- any -'
          },
          ...items
        ];
      }
      this.setState({
        items,
        selectedOption
      });
    } catch (e) {}
    setTimeout(() => {
      this._loadingOff();
    }, 100);
  }

  /**
   * Get model
   */
  _getModel() {
    if (this.model) return this.model;

    this.model = new this.props.model();

    return this.model;
  }

  /**
   * Loading off
   */
  _loadingOn() {
    this.setState({
      loading: true
    });
  }

  /**
   * Loading on
   */
  _loadingOff() {
    this.setState({
      loading: false
    });
  }

  render() {
    const { classes } = this.props;
    const validationError = this.getValidationError();

    const customStyles = {
      dropdownIndicator: base => ({
        ...base,
        cursor: 'pointer'
      }),
      clearIndicator: base => ({
        ...base,
        cursor: 'pointer'
      })
    };

    return (
      // <div className='input-group' style={{position: 'relative', width: '100% !important'}}>
      <div
        style={{
          position: 'relative',
          marginBottom: '20px',
          color: 'white',
          zIndex: 99,
          ...this.props.wrapperStyle
        }}
        className="select-wrapper"
      >
        <Select
          {...this.props}
          textFieldProps={this.props.textFieldProps}
          classes={classes}
          styles={customStyles}
          options={this.state.items}
          components={components}
          value={this.state.selectedOption}
          onChange={option => this.handleChange(option)}
          placeholder={this.props.placeholder}
          getOptionValue={option => this.props.getOptionValue(option)}
          getOptionLabel={option => this.props.getOptionLabel(option)}
        />
        {validationError}
      </div>
      //  </div>
    );
  }
}

AppSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  wrapperStyle: PropTypes.object,
  textFieldProps: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handler: PropTypes.object,
  model: PropTypes.object
};

AppSelect.defaultProps = {
  onChange: () => {},
  getOptionValue: option => option.id,
  getOptionLabel: option => option.name
};

export default withStyles(styles, { withTheme: true })(AppSelect);
