import { AppBadge, ModalDialog, SwitchButton } from '@launchpad';
import AppTable from '@launchpad/components/crud/AppTable';
import DeleteButton from '@launchpad/components/crud/DeleteButton';
import { date } from '@launchpad/util/DateHelper';
import RenderIfAllowed from '@launchpad/util/access-control/access-control.component';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UsersModel from './models/UsersModel';

interface HTMLInputElement extends HTMLElement {
  getContext(arg0: string): any;
  /**
   * Returns the value of the data at the cursor's current position.
   */
  value: string;
  history: any;
  justTable?: boolean;
}

export default class Users extends Component<HTMLInputElement, any> {
  _tableComponent: any;

  constructor(props: Readonly<HTMLInputElement>) {
    super(props);
    this.state = {};
  }

  onDeleteItem(id) {
    new UsersModel()
      .delete(id)
      .then(() => {
        this._tableComponent._refresh();
      })
      .catch(() => {
        this.setState({
          showInfoModal: true,
          infoModalContent: 'You can not delete this user!',
        });
      });
  }

  onChangeItemStatus(user) {
    let status = '';

    if (user.status === 'active') {
      status = 'DISABLED';
    } else status = 'ACTIVE';

    new UsersModel()
      .changeUserStatus(user.id, status)
      .then(() => {
        this._tableComponent._refresh();
      })
      .catch(() => {
        this.setState({
          showInfoModal: true,
          infoModalContent: 'Something went wrong!',
        });
      });
  }

  _renderEnableDisableSwitch(item) {
    return (
      <RenderIfAllowed
        action="Admin:User:Manage:*"
        renderOnForbidden={<em>-</em>}
      >
        <SwitchButton
          onChange={() => this.onChangeItemStatus(item)}
          isChecked={item.status === 'active'}
        />
      </RenderIfAllowed>
    );
  }

  _renderStatus(item) {
    switch (item.kycStatus) {
      case 'verified':
        return <AppBadge text="Verified" icon="check" />;
      case 'rejected':
        return <AppBadge text="Rejected" icon="times" type="danger" />;
      case 'unverified':
        return <AppBadge text="Unverified" icon="times" type="danger" />;
      case 'verification_in_progress':
        return (
          <AppBadge
            text="Verification in progress"
            icon="clock-o"
            type="info"
          />
        );
      case 'pending_frontend_verification':
        return (
          <AppBadge
            text="Pending frontend verification"
            icon="clock-o"
            type="info"
          />
        );
      default:
        return null;
    }
  }

  render() {
    const inner = (
      <>
        <AppTable
          icon="users"
          title="Users"
          onInit={(tableComponent) => (this._tableComponent = tableComponent)}
          headerActions={
            <RenderIfAllowed action="Admin:User:Manage:*">
              <Link
                to="/users/all-users/add-new"
                className="button width-240"
                style={{ marginRight: '10px' }}
              >
                <i className="fa fa-fw fa-plus" />
                Add new
              </Link>
            </RenderIfAllowed>
          }
          filter={[
            {
              name: 'term',
              type: 'term',
            },
          ]}
          order={[
            {
              field: 'id',
              direction: 'desc',
            },
          ]}
          model={UsersModel}
          renderCell={(column, item, index) => {
            switch (column.name) {
              case 'kycStatus':
                return this._renderStatus(item);
              case 'updatedAt':
                return date(item.updatedAt);
              case 'createdAt':
                return date(
                  item.createdAt || item.registeredAt || item.updatedAt
                );
              case 'userStatus':
                return this._renderEnableDisableSwitch(item);
              default:
            }
          }}
          getColumnActions={(member, index) => {
            return (
              <div>
                <Link to={`/user-details/${member.id}`} className="button-info">
                  Details
                </Link>
                <RenderIfAllowed action="Admin:User:Manage:*">
                  <DeleteButton
                    onConfirm={() => this.onDeleteItem(member.id)}
                    text={`Are you sure you wish to delete user "${`${member.firstName} ${member.lastName}`}"?`}
                  />
                </RenderIfAllowed>
              </div>
            );
          }}
        />
        <ModalDialog
          show={this.state.showInfoModal}
          onClose={() => this.setState({ showInfoModal: false })}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <p style={{ textAlign: 'center', paddingBottom: 20 }}>
              {this.state.infoModalContent}
            </p>
            <button
              type="button"
              onClick={() => this.setState({ showInfoModal: false })}
              className="button-info"
            >
              OK
            </button>
          </div>
        </ModalDialog>
      </>
    );

    if (this.props.justTable) {
      return inner;
    }

    return (
      <div className="dashboard-management">
        <h1>
          <i className="fa fa-users" aria-hidden="true" /> Users
        </h1>
        {inner}
      </div>
    );
  }
}
